import React, {useEffect, useState} from "react";
import {Box, Divider, Typography} from "@mui/material";
import QuestionDialogContent from "../QuestionDialogContent";
import {firestore} from "../../../firebase/clientApp";
import {useParams} from "react-router-dom";
import {useDocument} from "react-firebase-hooks/firestore";
import SuccessCheck from "../../shared/SuccessCheck";
import {motion, useMotionValue} from "framer-motion";

export const AltroQuestion = ({elementPlaying, theme, url, user}) => {
    return (
        <SessioneContent elementPlaying={elementPlaying} theme={theme} url={url} user={user}/>
    )
}

const SessioneContent = ({elementPlaying, theme, url, user}) => {
    console.log("elementPlaying:", elementPlaying)
    const selectedSession = sessioni[elementPlaying.id] // DEVE ESSERE L'ID DELLA DOMANDA DELLA SESSIONE X (tipo altro)

    const {id} = useParams()
    console.log("props:", user.uid)

    const [success, setSuccess] = useState(false)
    const [sendButton, setSendButton] = useState(false)

    let progress = useMotionValue(90)

    return (
        <Box pb={4}>
            {!success && !sendButton ? <>
                    <Typography gutterBottom variant={'h5'} textAlign={'center'}>
                        {selectedSession?.description || ''}
                    </Typography>
                    {<Box mt={5}>
                        {
                            selectedSession?.questions?.map((domanda, index) => (
                                <Box key={index} my={2}>
                                    <QuestionDialogContent idPlaying={domanda.id} url={url}
                                                           user={user}
                                                           theme={theme}/>
                                    <Divider/>
                                </Box>
                            ))
                        }
                        {/*<Box width={'50%'} margin={'auto'}>
                                <Button variant={'default'}
                                        disabled={!answers1?.data() || !answers2?.data() || !answers3?.data() || !answers4?.data()}
                                        onClick={() => setSendButton(true)}>
                                    Invia
                                </Button>
                            </Box>*/}
                    </Box>
                    }
                </>
                :
                <>
                    <Typography gutterBottom variant={'h5'}>Grazie per avere risposto!</Typography>
                    <Box pt={2} style={{width: '40%', margin: 'auto'}}>
                        <motion.div
                            initial={{x: 0}}
                            animate={{x: 100}}
                            style={{x: progress}}
                            transition={{duration: 0.8}}
                        />
                        <SuccessCheck progress={progress}/>
                    </Box>
                </>
            }
        </Box>
    )
}

const sessioni = {
    "JYwtNh5CRP9lLltkZqbu": {
        label: "FEEDBACK CONVENTION", // ITA VERSION
        description: "",
        questions: [
            {
                id: "NTszY2DfIvUWLcfiBrpW"
            },
            {
                id: "vkGqTvnM87tgAsOEICiT"
            },
            {
                id: "C9sPCwUujXOwI5i2IjBJ"
            },
            {
                id: "DK9BcTvcmOG9srwGE8SE"
            },
            {
                id: "ln0rO5Kg4P6A7yFSXAaI"
            },
            {
                id: "EWQlADd1Pm76w7sLmEkw"
            },
            {
                id: "8HKf3Ift85OEC1eKQoiT"
            },
            {
                id: "L2kHAUuwgkU6MEyakpCA"
            },
            {
                id: "9AUNaIES7TTe7B0DWVSw"
            },
            {
                id: "JrqphPFLrI9yBEJvpoQD"
            },
            {
                id: "AGhakj0VCKYkXznHGmji"
            }
        ]
    },
    "2rk7exXlX2sl99gHFFZU": {
        label: "FEEDBACK CONVENTION", // ENG VERSION
        description: "",
        questions: [
            {
                id: "Mm30qifmTaX0ag8i8cE3"
            },
            {
                id: "h5mLyCiLpuzv1HpznRd4"
            },
            {
                id: "G2OwUMj8rNkBkoBRYL7o"
            },
            {
                id: "FWuiFGh62a6c1psJZcTQ"
            },
            {
                id: "w4INaHgk56DDW7hrTf5P"
            },
            {
                id: "yJbtekLh2eOz5G1T7hL1"
            },
            {
                id: "4L2bR9onE3zeVj08Vz5h"
            },
            {
                id: "ReAINtWsQFdPlw87aMON"
            },
            {
                id: "EqrNh1eY8iIJ5iVefLvM"
            },
            {
                id: "OIeW0sOIyDM0sU6p34xb"
            },
            {
                id: "sDnvgD00FhuAsjm0A20v"
            }
        ]
    }
}