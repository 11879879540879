export const API_URL = "https://api.secondstage.app"
export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#e10714', '#e10714'],
        home_question: ['#e10714', '#e10714'],
        applausometro: ['#e10714', '#e10714'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#e10714', '#e10714'],
        qna: ['#EA4D95', '#E76E51'],
    },
    primary: '#e10714',
    secondary: '#263238',
    accent: '#EA4D95',
    text: '#eceff1',
    background:"#000000",
    poll:{
        //default:"#414272",
        default:"#e10714",
        answered:"rgba(225,7,20,0.6)",
        //answered:"#E5448A",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}