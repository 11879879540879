import React, {useEffect, useState} from "react";
import Applausometro from "./Applausometro";
import Question from "./Questions/Question";
import {useNavigate, useParams} from "react-router-dom";
import {firestore} from "../firebase/clientApp";
import {Box, CircularProgress, Stack} from "@mui/material";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {DisplayName} from "./DisplayName";
import {PinnedQuestions} from "./PinnedQuestions";

const LogoBox = ({logo}) => {
    return(
        <Box style={{width:'100%', height:'50%', marginTop:'0px'}} sx={{px: 2}}>
            <img style={{ margin:'auto', width:'100%', height:'100%', objectFit:'contain'}} src={logo}/>
        </Box>
    )
}

export default function VoteContent({user, event, loading, displayName, setDisplayName}) {
    const {id} = useParams()
    const [idPlaying, setIdPlaying] = useState('')
    const [eventPlaying, setEventPlaying] = useState('')
    const [pinned, pinnedLoading, pinnedError] = useCollection(firestore.collection('users/plesh/events/' + id + '/queue').where("pinned", "==",true), {})
    const url = 'users/plesh/events/'
    const [theme, themeLoading, themeError] = useDocument(firestore.collection('users/plesh/events/' + id + '/themes').doc(event?.data().theme))

    const navigate = useNavigate();

    useEffect(() => {
        getIdPlaying()
    }, [])

    useEffect(() => {

    }, [displayName]);

    function getIdPlaying() {
        firestore.collection(url).doc(id).onSnapshot(s => {
            let e = s.data().elementPlaying
            let g = s.data().playing
            setIdPlaying(e)
            setEventPlaying(g)
        })
    }

    const goToEng=()=>{
        navigate("/en");
    }

    const goToIta=()=>{
        navigate("/");
    }


    return (
        <Box>
            <Stack direction={"column"} justifyContent={"start"} alignContent={"center"} sx={{height: '56vh', py: 2}}
                   spacing={4}>
                <Box sx={{height: '4rem', display:'flex', flexBasis:'content'}}>
                    { id === 'K3neOfdqQrK2rLDVsUou' &&
                        <img
                            style={{width: '30px', marginLeft: 'auto'}}
                            src={'/united-kingdom.png'}
                            onClick={goToEng}
                        />
                    }
                    { id === 'L5zDGdNd5qqTyk2zqr5k' &&
                        <img
                            style={{width: '30px', marginLeft: 'auto'}}
                            src={'/italy.png'}
                            onClick={goToIta}
                        />
                    }
                </Box>
                {event.data().logo && <LogoBox
                    logo={event.data().logoUrl ? event.data().logoUrl : 'https://storage.googleapis.com/2ndstage-public/logos/logo-2nd.png'}/>}
                {
                    event.data().autenticazione &&
                    <DisplayName displayName={displayName} setDisplayName={setDisplayName}/>
                }
                {!pinnedLoading && !pinnedError && pinned ?
                    event.data().autenticazione
                        ?
                        (displayName !== '' && displayName) &&
                        <PinnedQuestions questions={pinned}/>
                        :
                        <PinnedQuestions questions={pinned}/>
                    :
                    <></>
                }
            </Stack>

            {/*
                <div style={{
                justifyContent: "center",
                display: "flex",
                width: '100%',
                height: '90px',
                position: 'relative',
                overflow: "hidden"
                }}>
                    <iframe style={{
                        width: '90%',
                        height: '190px', // Height of the iframe
                        position: 'relative',
                        top: '-50px', // Adjust according to the offset needed to center the content vertically
                    }} scrolling="no" frameBorder="no" allow="autoplay"
                            src="https://pop.converso.app/join/7PlrtnqQ1zPYHbptFGhu?r=mitsubishi2024"></iframe>
                </div>
            */}

            {loading ?
                <Box sx={{textAlign: 'center'}}>
                    <CircularProgress/>
                </Box>
                :
                event.data().applausometro ?
                    event.data().autenticazione
                        ?
                        (displayName !== '' && displayName) &&
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <Applausometro url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying}
                                           user={user}/>
                        </div>

                        :
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <Applausometro url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying}
                                           user={user}/>
                        </div>
                    :
                    <></>
            }
            {(theme && !themeLoading && !themeError && !loading) ?
                event.data().autenticazione
                    ?
                (displayName !== '' && displayName) &&
                    <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                    :
                    <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                :
                <></>
            }
        </Box>

    )
}
